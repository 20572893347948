import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

import LanguageDetector from 'i18next-browser-languagedetector';
import { sharesEN } from './en/shares';
import { sharesTH } from './th/shares';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'th',
    lng: 'th',
    ns: ['translation', 'user', 'long-term-rental', 'regions', 'booking-retrieve', 'car_deals'],
    defaultNS: 'translation',
    // debug: true,
    resources: {
      th: {
        translation: require('./th/translation.json'),
        user: require('./th/user.json'),
        'long-term-rental': require('./th/long-term-rental.json'),
        ...sharesTH
      },
      en: {
        translation: require('./en/translation.json'),
        user: require('./en/user.json'),
        'long-term-rental': require('./en/long-term-rental.json'),
        ...sharesEN
      }
    },
    react: {
      useSuspense: false,
      wait: true
    }
  });
export default i18n;
