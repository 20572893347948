export const sharesEN = {
  regions: {
    header: 'เช่ารถตามภูมิภาค',
    regions: {
      north: 'ภาคเหนือ',
      northEast: 'ภาคอีสาน',
      central: 'ภาคกลาง',
      east: 'ภาคตะวันออก',
      west: 'ภาคตะวันตก',
      south: 'ภาคใต้',
      other: 'อื่นๆ'
    }
  },
  'booking-retrieve': {
    title: 'ตรวจสอบการจองของคุณ',
    'placeholder-booking-id': 'กรอกหมายเลขการจอง',
    'placeholder-last-name': 'กรอกนามสกุล',
    'btn-submit': 'ตรวจสอบ',
    'error-message': 'ไม่พบการจองของคุณ กรุณาลองใหม่อีกครั้ง'
  },
  'car-deals': {
    header: 'รถเช่าราคาพิเศษ',
    remark: '*ราคายังไม่รวมค่าบริการนอกเวลาทำการ',
    card: {
      header: 'เริ่มต้น',
      empty: 'ไม่มีผู้ให้บริการ',
      unit: 'บาท/วัน',
      subtitle: 'บริษัทรถเช่าที่ให้บริการ'
    }
  }
};
