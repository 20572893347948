import { PureComponent } from 'react';
import i18next from './config';
import { I18nextProvider, withTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/ban-types
export const withTrans: Function = (namespace: string) => (WrappedComponent: React.ComponentType<any>) => {
  WrappedComponent = withTranslation(namespace)(WrappedComponent);

  return class ComponentWithT extends PureComponent {
    render() {
      return (
        <I18nextProvider i18n={i18next}>
          <WrappedComponent {...this.props} />
        </I18nextProvider>
      );
    }
  };
};
